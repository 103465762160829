import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const GenderRoundGraph = ({ fbAdInsightsByGender }) => {
  const user = useSelector((state) => state.user);

  // Bind clicks data by gender
  const genderBreakdownData = {
    male: fbAdInsightsByGender?.find((item) => item.gender === "male")?.clicks || 0,
    female: fbAdInsightsByGender?.find((item) => item.gender === "female")?.clicks || 0,
    unknown: fbAdInsightsByGender?.find((item) => item.gender === "unknown")?.clicks || 0,
  };

  // Store full data by gender for tooltip use
  const genderDetails = {
    male: fbAdInsightsByGender?.find((item) => item.gender === "male") || {},
    female: fbAdInsightsByGender?.find((item) => item.gender === "female") || {},
    unknown: fbAdInsightsByGender?.find((item) => item.gender === "unknown") || {},
  };

  const value = [
    {
      name: "Male",
      value: genderBreakdownData.male,
      details: genderDetails.male,
    },
    {
      name: "Female",
      value: genderBreakdownData.female,
      details: genderDetails.female,
    },
    {
      name: "Unknown",
      value: genderBreakdownData.unknown,
      details: genderDetails.unknown,
    },
  ];

  let updatedOption = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        const data = params.data.details;
        return `
          <b>${params.name}</b><br/>
          Clicks: ${params.value}<br/>
          Impressions: ${data.impressions || 'N/A'}<br/>
          Reach: ${data.reach || 'N/A'}<br/>
          Spend: $${data.spend || 'N/A'}<br/>
          Frequency: ${data.frequency || 'N/A'}<br/>
          CTR: ${data.ctr || 'N/A'}
        `;
      },
    },
    legend: {
      orient: "vertical",
      right: 1,
      top: "center",
      icon: "circle",
      textStyle: {
        fontSize: 14,
        fontWeight: 500,
      },
      itemGap: 15,
      itemWidth: 10,
      itemHeight: 10,
      data: value.map((v) => v.name),
    },
    series: [
      {
        name: "Clicks by Gender",
        type: "pie",
        radius: ["0%", "80%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        data: value.map((v) => ({
          name: v.name,
          value: v.value,
          details: v.details,
        })),
        color: ["#FCBE72", "#D995FA", "#906DFE"],
        left: "0%",
        center: ["40%", "50%"],
      },
    ],
  };

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default GenderRoundGraph;