import React, { useEffect } from 'react'
import methodModel from '../../methods/methods'
import ApiClient from '../../methods/api/apiClient';
import { useNavigate } from 'react-router-dom';
import loader from '../../methods/loader';
import { useSelector } from 'react-redux';

export default function QuickBookConnect() {
    const code = methodModel.getPrams('code');
    const user = useSelector(state => state.user);
    const Navigate = useNavigate()
    useEffect(() => {
        loader(true)
        if (code) {
            localStorage.setItem('realmId', methodModel.getPrams('realmId'));
            ApiClient.get(`api/quickbook/callback`, {
                code, baseurl: `https://${window.location.host}/connect/quickbook`, 'userId': user?._id || user?.id, realmId: methodModel.getPrams('realmId')
            }).then(res => {
                if (res.success) {
                    ApiClient.post('api/quickbook/invoicelist', {
                        "quickbook_token": res?.data?.access_token,
                        "startposition": res?.data?.startposition,
                        "maxresults": res?.data?.maxresults,
                        'company': methodModel.getPrams('realmId') || '9341452853606057',
                        'userId': user?._id || user?.id
                    })
                    ApiClient.post(`api/quickbook/transction/list`, {
                        "quickbook_token": res?.data?.access_token,
                        "company": methodModel.getPrams('realmId') || '9341452853606057',
                        "startDate": "",
                        "endDate": "",
                        'userId': user?._id || user?.id
                    })
                    Navigate('/api/accounting')
                }
                loader(false)
            })
        } else {
            Navigate('/api/accounting')
            loader(false)
        }
    }, [code])
    return (
        <div>QuickBookConnect</div>
    )
}
