import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const PublisherPlatformLineBarGraph = ({ fbAdInsightsByPublisherPlatform = [] }) => {
  const user = useSelector((state) => state.user);

  // Map the data to extract relevant details for the graph based on publisher platform
  const platformData = fbAdInsightsByPublisherPlatform.map((item) => ({
    publisher_platform: item.publisher_platform,
    clicks: Number(item.clicks) || 0, // Ensure clicks are numbers
    impressions: item.impressions || 0,
    spend: item.spend || 0,
    ctr: item.ctr || 0,
    cpm: item.cpm || 0,
  }));

  // Tooltip formatter for detailed information
  let updatedOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: (params) => {
        const data = params[0].data;
        return `
          <b>Platform: ${data.publisher_platform}</b><br/>
          Clicks: ${data.clicks}<br/>
          Impressions: ${data.impressions}<br/>
          Spend: $${data.spend}<br/>
          CTR: ${data.ctr}%<br/>
          CPM: $${data.cpm}
        `;
      },
    },
    xAxis: {
      type: "category",
      data: platformData.map((item) => item.publisher_platform), // Set publisher platforms on the x-axis
    },
    yAxis: {
      type: "value",
      name: "Clicks", // Label the y-axis as "Clicks"
      axisLabel: {
        formatter: (value) => value, // Display the raw number of clicks
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    series: [
      {
        name: "Clicks",
        type: "bar",
        barWidth: "50%",
        data: platformData.map((item) => ({
          value: item.clicks, // Y-axis contains clicks
          ...item, // Spread the remaining data for use in the tooltip
        })),
        itemStyle: {
          borderRadius: [4, 4, 0, 0],
          color: "#8986E7",
        },
      },
    ],
    color: ["#A085E6"],
  };

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default PublisherPlatformLineBarGraph;