import React, { useEffect, useState } from "react";
import methodModel from "../../../methods/methods";
import Html from "./html";

const MultiSelectDropdown = ({ intialValue, options, isSingle = false, result, displayValue = 'name', id, isObject = false, placeholder, disabled }) => {

    const [selectedValues, setSelectedValues] = useState([])
    const [loader, setLoader] = useState(false)

    const handleChange = (e, type) => {
        let value = []

        if (isSingle) {
            value = isObject ? e[0] : e[0].id
        } else {
            value = e.map(itm => {
                return isObject ? itm : itm.id
            })
        }
        result({ event: "value", value: value })
    }

    useEffect(() => {
        if (isSingle) {
            let ext = methodModel.find(options, intialValue, 'id')
            if (ext) setSelectedValues([isObject ? intialValue : ext])
        } else {
            let value = []
            if (intialValue?.length && options?.length) {
                value = intialValue?.map(itm => {
                    return {
                        ...methodModel.find(options, itm, 'id'),
                        id: methodModel.find(options, itm, 'id')?.id || '',
                        [displayValue]: methodModel.find(options, itm, 'id')?.[displayValue] || 'Not Exist'
                    }
                })
            }
            setSelectedValues([...value])
        }

        if (!intialValue?.length) {
            setLoader(true)
            setTimeout(() => {
                setLoader(false)
            }, 100)
        }
    }, [intialValue, options])

    return <>

        {loader ? <>
            <div className="shine shineInput"></div>
        </> : <>
            <Html
                id={id}
                isSingle={isSingle}
                displayValue={displayValue}
                options={options}
                selectedValues={selectedValues}
                handleChange={handleChange}
                placeholder={placeholder}
                disabled={disabled}
            />
        </>}
    </>
}

export default MultiSelectDropdown