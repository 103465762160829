import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const FbLineCurveGraph = ({ fbAdInsightsYearly }) => {
  const user = useSelector((state) => state.user);

  // Map fbAdInsightsYearly data to fit the required structure
  const accountOverviewData = fbAdInsightsYearly.map((item) => ({
    month: new Date(item.date_start).toLocaleString("default", { month: "short" }), // Format month from date
    totalImpressions: parseInt(item.impressions, 10),
    clicks: parseInt(item.clicks, 10),
    cpc: item.cpc,
    ctr: item.ctr,
    reach: item.reach,
    spend: item.spend,
    percentage: `${((item.clicks / item.impressions) * 100).toFixed(2)}%`,
  }));

  let updatedOption = {
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        const index = params[0].dataIndex;
        const monthData = accountOverviewData[index];
        return `
          <div style="color: #000;">
            <strong style="color: #000;">Data for ${monthData.month}</strong><br/>
            <span style="color: #0E9F6E;">Total Impressions: </span>
            <span style="color: #333;">${monthData.totalImpressions} (${monthData.percentage})</span><br/>
            <span style="color: #FF5733;">Clicks: </span>
            <span style="color: #333;">${monthData.clicks}</span><br/>
            <span style="color: #C70039;">CPC: </span>
            <span style="color: #333;">$${monthData.cpc}</span><br/>
             <span style="color: #C70039;">CTR: </span>
            <span style="color: #333;">${monthData.ctr}%</span><br/>
             <span style="color: #C70039;">Reach: </span>
            <span style="color: #333;">${monthData.reach}</span><br/>
             <span style="color: #C70039;">Spend: </span>
            <span style="color: #333;">$${monthData.spend}</span><br/>
          </div>`;
      },
      backgroundColor: "#fff",
      borderColor: "#fff",
      borderWidth: 1,
      padding: 10,
      textStyle: {
        color: "#333",
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: accountOverviewData.map((item) => item.month),
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => `${value / 1000}k`,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    series: [
      {
        name: "Account Overview",
        type: "line",
        smooth: true,
        data: accountOverviewData.map((item) => item.totalImpressions),
        itemStyle: {
          color: "#0E9F6E",
        },
        lineStyle: {
          color: "#0E9F6E",
          width: 2,
        },
        symbol: "circle",
        symbolSize: 8,
        emphasis: {
          itemStyle: {
            borderWidth: 2,
            borderColor: "#FFFFFF",
          },
        },
        areaStyle: {
          color: "rgba(14, 201, 141, 0.2)",
        },
      },
    ],
  };

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default FbLineCurveGraph;