/*
 * @file: index.js
 * @description: It Contain rest functions for api call .
 * @author: Poonam
 */

import axios from 'axios';
import querystring from 'querystring';
// import { setAuthorizationToken } from '../auth';
import { toast } from 'react-toastify';
import loader from '../loader';
import environment from '../../environment';
import methodModel from '../methods';


let config = {
    headers: {
        'Content-Type': 'application/json',
        //    'Access-Control-Allow-Origin':'*'
    },
};

let baseUrl = environment.chatbot_api


const handleError = (err, hideError) => {
    let message = ''
    if (err) {
        if (err && err.error && err.error.code == 401) {
            localStorage.removeItem("persist:admin-app")
            localStorage.removeItem("token")

            hideError = true
            if (window.location.pathname == '/company') {
            } else {
                methodModel.route('/')
            }
        }
        if (err.error && err.error.message == 'Please verify your email first.') {
            methodModel.route('/otppage')
        }
        message = err && err.error && err.error.message
        if (!message) message = err.message
        if (!message) message = 'Server Error'
    }
    if (!hideError) toast.error(message || "Network Error");
}

class ChatbotApiClient {
    static post(url1, params, base = '', hidError = '') {
        let url = baseUrl + url1
        if (base) url = base + url1

        // setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            axios
                .post(url, JSON.stringify(params), config)
                .then(function (response) {
                    fulfill(response && response.data);
                })
                .catch(function (error) {
                    loader(false)
                    if (error && error.response) {
                        let eres = error.response;



                        handleError(eres.data, hidError)
                        fulfill({ ...eres.data, success: false });
                    } else {
                        // toast.error('Network Error')
                        reject(error);
                    }
                });
        });
    }

    static put(url1, params, base = '') {
        let url = baseUrl + url1
        if (base) url = base + url1
        // setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            axios
                .put(url, JSON.stringify(params), config)
                .then(function (response) {
                    fulfill(response && response.data);
                })
                .catch(function (error) {
                    loader(false)
                    if (error && error.response) {
                        let eres = error.response;
                        handleError(eres.data)
                        fulfill(eres.data);
                    } else {
                        // toast.error('Network Error')
                        reject(error);
                    }
                });
        });
    }

    static get(url1, params = {}, base = '', hidError = '', obj = { apiCall: false, cancelTokenSource: '' }) {

        let url = baseUrl + url1
        if (base) url = base + url1

        let query = querystring.stringify(params);
        url = query ? `${url}?${query}` : url;
        if (obj.apiCall) {
            sessionStorage.removeItem(url);
        }
        // setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            if (!obj?.apiCall) {
                let sdata = sessionStorage.getItem(String(url))
                if (sdata) {
                    if (obj?.response) obj.response(JSON.parse(sdata))
                    fulfill(JSON.parse(sdata));
                }
            }

            let newconfig = config;
            if (obj.cancelTokenSource?.token) {
                newconfig['cancelToken'] = obj.cancelTokenSource?.token
            }
            axios
                .get(url, newconfig)
                .then(function (response) {
                    let data = response && response.data
                    if (obj?.response) obj.response(data)
                    fulfill(data);

                })
                .catch(function (error) {
                    loader(false)
                    if (error && error.response) {
                        let eres = error.response;
                        if (!hidError) {
                            handleError(eres.data, hidError)
                        }
                        let err = { ...eres.data, success: false }
                        if (obj?.response) obj.response(err)
                        fulfill(err);
                    } else {
                        // toast.error('Network Error')
                        if (obj?.response) obj.response(error)
                        reject(error);
                    }
                });


        });
    }

    static getAll({ url, params = {}, base = '', hidError = '', apiCall = false, cancelTokenSource = '', response = () => { } }) {
        return this.get(url, params, base, hidError, { apiCall: apiCall, response: response, cancelTokenSource: cancelTokenSource })
    }


    static delete(url1, params = {}, base = '') {
        let url = baseUrl + url1
        if (base) url = base + url1

        let query = querystring.stringify(params);
        url = query ? `${url}?${query}` : url;
        // setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            axios
                .delete(url, config)
                .then(function (response) {
                    fulfill(response && response.data);
                })
                .catch(function (error) {
                    loader(false)
                    if (error && error.response) {
                        let eres = error.response;
                        handleError(eres.data)
                        fulfill(eres.data);
                    } else {
                        // toast.error('Network Error')
                        reject(error);
                    }
                });
        });
    }

    static allApi(url, params, method = 'get') {
        if (method == 'get') {
            return this.get(url, params)
        } else if (method == 'put') {
            return this.put(url, params)
        } if (method == 'post') {
            return this.post(url, params)
        }
    }

    /*************** Form-Data Method ***********/
    static postFormData(url, params) {
        url = baseUrl + url
        // setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            let body = new FormData();
            let oArr = Object.keys(params)
            oArr.map(itm => {
                body.append(itm, params[itm]);
            })

            axios
                .post(url, body, config)

                .then(function (response) {
                    fulfill(response && response.data);
                })
                .catch(function (error) {
                    loader(false)
                    if (error && error.response) {
                        let eres = error.response;
                        handleError(eres.data)
                        fulfill(eres.data);
                    } else {
                        // toast.error('Network Error')
                        reject(error);
                    }
                });
        });
    }

    static async multiImageUpload(url, params) {
        url = baseUrl + url
        // setAuthorizationToken(axios);
        let body = new FormData();
        let oArr = Object.keys(params)
        oArr.map(itm => {
            body.append(itm, params[itm]);
        })

        return await axios
            .post(url, body, config)

            .then(function (response) {
                return response && response.data
            })
            .catch(function (error) {
                return error && error.response
            });
    }

    static async fetch(url) {
        url = baseUrl + url
        const response = await fetch(url);
        const jsonResponse = await response.json();
        if (response.ok) {
            return jsonResponse
        }
    }

}

export default ChatbotApiClient;
