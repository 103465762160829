export const GmailTempAPIS = {
  list: "api/template/gmail/all",
  statusChange: "api/template/gmail/status",
  delete: "api/template/gmail",
  add: "api/template/gmail",
  edit: "api/template/gmail",
  view: "api/template/gmail",
  export: "",
};
export const GmailTempURL = {
  add: "/gmail/template/add",
  edit: "/gmail/template/edit",
  copy: "/gmail/template/add",
  list: "/gmail/template",
};
export const GmailTempConfig = {
  ListHeader: "Email Templates",
  AddHear: " Email Template",
};

export const GmailTempInputs = [
  {
    name: "title",
    required: true,
    placeHolder: "Enter Template Name",
    outerClass: "col-span-2 mb-2 lg:col-span-12",
    label: "Add Email Template",
    type: "text",
    className:
      "shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary",
  },
  {
    name: "content",
    required: true,
    placeHolder: "Enter Content here...",
    outerClass: "col-span-2 mb-2 lg:col-span-12",
    label: "Enter Content",
    type: "textarea",
    className:
      "shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-96 p-4 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary resize-none",
  },
];
