import React from "react";
import Multiselect from 'multiselect-react-dropdown';
import { IoIosArrowDown } from "react-icons/io";

const Html = ({ options, selectedValues, isSingle = false, handleChange, displayValue, id, placeholder, disabled }) => {
  return <>
    <div className="selectDropdown relative cursor-pointer">
      <Multiselect
        options={options}
        singleSelect={isSingle}
        selectedValues={selectedValues}
        onSelect={e => handleChange(e, 'select')}
        onRemove={e => handleChange(e, 'remove')}
        disable={disabled}
        displayValue={displayValue}
        id={id}
        placeholder={placeholder || 'Select'}
      />
      <div className="absolute top-3 right-2 cursor-pointer">
        <IoIosArrowDown className="text-lg text-[#75757A]" />
      </div>
    </div>

  </>
}

export default Html