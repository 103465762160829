import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const PricingCurveLineGraph = () => {
  const user = useSelector((state) => state.user);

  const data2022 = [
    { day: "Jan 1", value: 100 },
    { day: "Jan 5", value: 150 },
    { day: "Jan 8", value: 200 },
    { day: "Jan 12", value: 250 },
    { day: "Jan 15", value: 300 },
    { day: "Jan 18", value: 450 },
    { day: "Jan 21", value: 600 },
  ];

  const predictedBooking = [
    { day: "Jan 1", value: 150 },
    { day: "Jan 5", value: 250 },
    { day: "Jan 8", value: 300 },
    { day: "Jan 12", value: 350 },
    { day: "Jan 15", value: 400 },
    { day: "Jan 18", value: 450 },
    { day: "Jan 21", value: 500 },
  ];

  const actualBooking = [
    { day: "Jan 1", value: 120 },
    { day: "Jan 5", value: 200 },
    { day: "Jan 8", value: 250 },
    { day: "Jan 12", value: 300 },
    { day: "Jan 15", value: 350 },
    { day: "Jan 18", value: 400 },
    { day: "Jan 21", value: 450 },
  ];
  const breakevenLine = [
    { day: "Jan 1", value: 80 },
    { day: "Jan 5", value: 100 },
    { day: "Jan 8", value: 120 },
    { day: "Jan 12", value: 200 },
    { day: "Jan 15", value: 250 },
    { day: "Jan 18", value: 300 },
    { day: "Jan 21", value: 250 },
  ];

  const capacityLine = Array(7).fill(35);

  let updatedOption = {
    backgroundColor: "transparent",
    tooltip: {
      trigger: "axis",
    },
    // legend: {
    //   data: [
    //     { name: "2022", icon: "rect" },
    //     { name: "Capacity - 35 pax", icon: "rect" },
    //     { name: "Predicted Booking", icon: "rect" },
    //     { name: "Actual Booking", icon: "rect" },
    //     { name: "Breakeven", icon: "rect" },
    //   ],
    //   orient: "vertical", 
    //   right: "0%",        
    //   top: "25%",        
    //   textStyle: {
    //     fontSize: 14,
    //     fontWeight: 500,
    //     color: "#92929D",
    //   },
    //   itemGap: 20, 
    // },
    
    legend: {
        show: false,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: data2022.map((item) => item.day),
      axisLabel: {
        fontSize: 12,
        color: "#92929D",
      },
      splitLine: {
        show: false, 
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => `${value}`,
        fontSize: 12,
        color: "#92929D",
      },
      splitLine: {
        show: false, 
      },
    },
    grid: {
      left: "3%",
      right: "5%",  
      bottom: "10%",
      containLabel: true,
      width: "80%", 
    },
    series: [
      {
        name: "2022",
        type: "line",
        smooth: true,
        data: data2022.map((item) => item.value),
        itemStyle: {
          color: "#FD9210",
        },
        lineStyle: {
          color: "#FD9210",
          width: 1,
        },
        label: {
          show: false,
        },
        symbol: "none",
      },
      {
        name: "Capacity - 35 pax",
        type: "line",
        smooth: true,
        data: capacityLine,
        itemStyle: {
          color: "#6B40F2",
        },
        lineStyle: {
          type: "solid",
          color: "#6B40F2",
          width: 1,
        },
        label: {
          show: false,
        },
        symbol: "none",
      },
      {
        name: "Predicted Booking",
        type: "line",
        smooth: true,
        data: predictedBooking.map((item) => item.value),
        itemStyle: {
          color: "#3A89C2",
        },
        lineStyle: {
          color: "#3A89C2",
          width: 1,
        },
        // areaStyle: {
        //   color: "rgba(47, 128, 237, 0.1)",
        // },
        label: {
          show: false,
        },
        symbol: "none",
      },
      {
        name: "Actual Booking",
        type: "line",
        smooth: true,
        data: actualBooking.map((item) => item.value),
        itemStyle: {
          color: "#369C8E",
        },
        lineStyle: {
          color: "#369C8E",
          width: 1,
        },
        // areaStyle: {
        //   color: "rgba(39, 174, 96, 0.1)",
        // },
        label: {
          show: false,
        },
        symbol: "none",
      },
      {
        name: "Breakeven",
        type: "line",
        smooth: true,
        data: breakevenLine.map((item) => item.value),
        itemStyle: {
          color: "#E92531",
        },
        lineStyle: {
          type: "solid",
          color: "#E92531",
          width: 1,
        },
        label: {
          show: false,
        },
        symbol: "none",
      },
    ],
  };
  
  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default PricingCurveLineGraph;
