import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const GoogleDeviceRoundGraph = ({ googleAdInsightsByDevice }) => {
  const user = useSelector((state) => state.user);

  // Aggregate data by device platform
  const devicePlatformData = googleAdInsightsByDevice.map((item) => ({
    device: item.segments.device,
    clicks: parseInt(item.metrics.clicks, 10),
    impressions: item.metrics.impressions,
    costMicros: item.metrics.costMicros,
    ctr: item.metrics.ctr,
    allConversions: item.metrics.allConversions,
    averageCpc: item.metrics.averageCpc,
    averageCpm: item.metrics.averageCpm,
  }));

  // Prepare data for the chart
  const chartData = devicePlatformData.map((item) => ({
    name: item.device.charAt(0).toUpperCase() + item.device.slice(1),
    value: item.clicks,
    details: {
      impressions: item.impressions,
      costMicros: item.costMicros,
      ctr: item.ctr,
      allConversions: item.allConversions,
      averageCpc: item.averageCpc,
      averageCpm: item.averageCpm,
    },
  }));

  let updatedOption = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        const data = params.data.details;
        return `
          <b>${params.name}</b><br/>
          Clicks: ${params.value}<br/>
          Impressions: ${data.impressions || 'N/A'}<br/>
          Cost: $${parseFloat(data.costMicros).toFixed(2)}<br/>
          CTR: ${parseFloat(data.ctr).toFixed(2)}%<br/>
          Conversions: ${data.allConversions || 'N/A'}<br/>
          Avg CPC: $${parseFloat(data.averageCpc).toFixed(2)}<br/>
          Avg CPM: $${parseFloat(data.averageCpm).toFixed(2)}
        `;
      },
    },
    legend: {
      orient: "vertical",
      right: 1,
      top: "center",
      icon: "circle",
      textStyle: {
        fontSize: 14,
        fontWeight: 500,
      },
      itemGap: 15,
      itemWidth: 10,
      itemHeight: 10,
      data: chartData.map((v) => v.name),
    },
    series: [
      {
        name: "Clicks by Device Platform",
        type: "pie",
        radius: ["0%", "80%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        data: chartData,
        color: ["#FCBE72", "#D995FA", "#906DFE", "#88DF99"], // added colors for more devices
        left: "0%",
        center: ["40%", "50%"],
      },
    ],
  };

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default GoogleDeviceRoundGraph;