import React from "react";
import ReactEcharts from "echarts-for-react";

const Chart = ({ options, height = "320px", width = "100%" }) => {
  return (
    <ReactEcharts
      option={options}
      style={{ width: width, height: height }}></ReactEcharts>
  );
};

export default Chart;
