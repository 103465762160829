import { useState } from 'react';

const ProductTable = ({ AllProducts, formState, toggleProductSelection, ButtonSaved }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredProducts = AllProducts?.filter((product) =>
        product?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="p-4">
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Search Product"
                    className="px-4 py-2 w-full border rounded-md"
                    value={searchTerm}
                    disabled={ButtonSaved}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div style={{ height: '450px', overflowY: 'scroll' }}>
                <table className="table-fixed ">
                    <thead>
                        <tr className="bg-gray-100 text-left">
                            <th className="px-4 py-2"><input
                                type="checkbox"
                                disabled={ButtonSaved}
                                className="h-4 w-4  disabled cursor-pointer rounded-[4px] border border-[#3C3E49A3] !text-primary"
                                checked={formState?.products?.length == AllProducts?.length}
                                onChange={(e) => toggleProductSelection('all', e.target.checked)}
                            /></th>
                            <th className="px-4 py-2">Product Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProducts?.map((product) => (
                            <tr key={product.id} className="border-t">
                                <td className="px-4 py-2">
                                    <input
                                        type="checkbox"
                                        disabled={ButtonSaved}
                                        className="h-4 w-4 disabled cursor-pointer rounded-[4px] border border-[#3C3E49A3] !text-primary"
                                        checked={formState.products.includes(product.id)}
                                        onChange={() => toggleProductSelection(product.id)}
                                    />
                                </td>
                                <td className="px-4 py-2 capitalize text-[#344054] text-sm font-normal">
                                    {product.name}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductTable;
