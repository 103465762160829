import React, { useEffect, useState } from 'react'
import dynamicPriceModel from '../../../models/dynamicPrice.model';
import DateRangePicker from '../../../components/common/DateRangePicker';
import SelectDropdown from '../../../components/common/SelectDropdown';
import { useParams } from 'react-router-dom';
import loader from '../../../methods/loader';
import ApiClient from '../../../methods/api/apiClient';
import moment from 'moment';
import methodModel from '../../../methods/methods';

export default function DynamicPicingProcharter({ ProcharterDP, setProcharterDP, productCode, getData, proDetail }) {
    const { id } = useParams();
    const [submitted, setsubmitted] = useState(false);
    const [AllPrices, setAllPrices] = useState([]);
    const back = () => {
        setProcharterDP(false);
        setform({ name: '', startDate: '', endDate: '', priceType: '' });
    }
    const [form, setform] = useState({ startDate: '', endDate: '' });
    const handleSubmit = (e) => {
        e.preventDefault()
        setsubmitted(true);
        if (!form?.startDate || !form?.endDate || !form?.name) {
            return
        }
        if (!form?.priceType) {
            return
        }
        loader(true);
        ApiClient.get(`api/product/availablity?productCode=${productCode}&startTimeLocal=${methodModel.MomentFormat(form?.startDate, 'YYYY-MM-DD')}+00:00:00&endTimeLocal=${methodModel.MomentFormat(form?.endDate, 'YYYY-MM-DD')}+23:59:00&count=100&offset=0
`).then(res => {
            if (res.success) {
                PriceUpdateHandler(res?.data?.map((item) => item?.ID))
            }
        })

    }
    const PriceUpdateHandler = (runIDs = []) => {
        const Payload = {
            "runIDs": runIDs,
            "priceType": form?.priceType,
            "id": id
        }

        ApiClient.put(`api/procharter/price/types/update`, Payload).then(res => {

        })
        let method = 'post';
        let url = 'api/apply/dynamic/pricing/procharter';
        let UpdatePayload = {
            "productId": id,
            "name": form?.name,
            "changesDate": form?.startDate,
            "changesDateTo": form?.endDate,
            "prochaterPriceType": form?.priceType,
            type: 'array'
        }
        if (proDetail && proDetail?.id) {
            method = 'put';
            url = 'api/product/pricing/update';
            UpdatePayload['id'] = proDetail?.id || proDetail?._id
        }

        ApiClient.allApi(url, UpdatePayload, method).then(Res => {
            if (Res.success) {
                setform({ name: '', startDate: '', endDate: '', priceType: '' })
                getData()
                setProcharterDP(false);
            }
            loader(false)
        })
    }
    const GetAllPrices = () => {
        loader(true);
        ApiClient.get(`api/procharter/price/types?id=${id}`).then(res => {
            if (res.success) {
                setAllPrices(res?.data?.map((item) => ({ id: item?.ID, name: item?.Description })))
            }
            loader(false)
        })
    }
    useEffect(() => {
        if (proDetail) {
            setform({ startDate: proDetail?.changesDate, endDate: proDetail?.changesDateTo, name: proDetail?.name, priceType: proDetail?.prochaterPriceType })
        }
        if (id) {
            GetAllPrices()
        }
    }, [id])
    return (
        <div>
            <div className="px-6 pb-3">

                <div className="grid grid-cols-12 gap-4">
                    <div className="xl:col-span-6 shadow-box border !border-grey col-span-9 p-6 bg-white rounded-large">

                        <div className="flex flex-col gap-1  mb-6">
                            <h4 className="text-2xl font-semibold text-[#111827]">

                                <>{proDetail && proDetail.id ? "Edit" : "Add"} Dynamic Pricing</>
                            </h4>

                        </div>

                        <form onSubmit={handleSubmit} name="dynamicPricing">
                            <div className="!mb-5">
                                <label className="text-[#75757A] text-sm font-normal mb-2  lg:h-10 line-clamp-1 min-[1439px]:h-auto xl:block">
                                    Rule Name<span className="star">*</span>
                                </label>
                                <input
                                    className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                                    value={form?.name}
                                    onChange={e => setform(prev => ({ ...prev, name: e.target.value }))}
                                    placeholder='Enter Name here ..'
                                />
                                {submitted && (!form?.name) ? <><p className='text-red-400 ml-2 mt-2'>Rule Name  is required</p></> : null}
                            </div>
                            <div className="!mb-5">
                                <label className="text-[#75757A] text-sm font-normal mb-2  lg:h-10 line-clamp-1 min-[1439px]:h-auto xl:block">
                                    Select Date<span className="star">*</span>
                                </label>
                                <DateRangePicker
                                    allTime={true}
                                    buttonShow={true}
                                    value={{
                                        startDate: form?.startDate,
                                        endDate: form?.endDate,
                                    }}
                                    onChange={e => setform(prev => ({ ...prev, startDate: e?.startDate, endDate: e?.endDate }))}
                                />
                                {submitted && (!form?.startDate || !form?.endDate) ? <><p className='text-red-400 ml-2 mt-2'>Date is required</p></> : null}
                            </div>
                            <div className="!mb-5">
                                <label className="text-[#75757A] text-sm font-normal mb-2 lg:h-10 line-clamp-1 min-[1439px]:h-auto xl:block">
                                    Select Price Type<span className="star">*</span>
                                </label>
                                <SelectDropdown
                                    theme={'search'}
                                    intialValue={form?.priceType}
                                    result={e => setform(prev => ({ ...form, priceType: e?.value }))}
                                    options={AllPrices}
                                    placeholder='Select Price Type'
                                />
                                {submitted && (!form?.priceType) ? <><p className='text-red-400 ml-2 mt-2'>Price Type is required</p></> : null}
                            </div>
                            <div>


                                <div className="flex items-center justify-end gap-2 mt-6">
                                    <button
                                        type="button"
                                        className="!px-4 text-typo text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-extra_large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                                        onClick={(e) => back()}>
                                        Back
                                    </button>

                                    <>
                                        <button
                                            type="submit"
                                            className="bg-white !px-4 text-sm font-normal text-primary h-10 flex items-center justify-center gap-2 rounded-extra_large shadow-btn hover:bg-primary hover:text-white border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                                            Save
                                        </button>
                                    </>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}
