/*
 * @file: configureStore.prod.js
 * @description: Configure/creating redux store with thunk, reducer etc
 * @author: ANil
 * */

import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk'; // Changed to default import
// import { routerMiddleware } from 'connected-react-router';
import reducers from '../reducers';

// Enable Redux DevTools in development mode
const composeEnhancers =
    (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

export default history => {
    const store = createStore(
        reducers,
        composeEnhancers(applyMiddleware(thunk)) // Ensure correct application of middleware
    );
    const persistor = persistStore(store);
    return { persistor, store };
};
