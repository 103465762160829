// src/Calendar.js
import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { LiaFlag } from "react-icons/lia";
import { LuCalendarDays } from "react-icons/lu";
import { FaStar } from "react-icons/fa";



const BookingCalendar = ({ ProductData, ProductState, setIsOpen, setCartData }) => {
  const [Availablity, setAvailability] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [CurrentProductData, setCurrentProductData] = useState(null);

  useEffect(() => {
    setCurrentProductData({ ...ProductData })
  }, [ProductData])
  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleMonthChange = (month) => {
    setCurrentMonth(month);
    setSelectedDate(null); // Reset selection on month change
    fetchAvailability(month, currentYear); // Fetch new availability when month changes
  };

  const handleYearChange = (direction) => {
    const newYear = currentYear + direction;
    setCurrentYear(newYear);
    setSelectedDate(null); // Reset date selection when the year changes
    fetchAvailability(currentMonth, newYear); // Fetch new availability when year changes
  };

  const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();
  const getStartDayOfMonth = (month, year) => new Date(year, month - 1, 1).getDay();

  const daysInMonth = getDaysInMonth(currentMonth, currentYear);
  const startDay = getStartDayOfMonth(currentMonth, currentYear);
  const emptyCells = Array.from({ length: startDay }, (_, i) => i);
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const fetchAvailability = (month, year) => {
    const start = `${year}-${String(month).padStart(2, '0')}-01`;
    const end = `${year}-${String(month).padStart(2, '0')}-${daysInMonth}`;

    const filter = {
      productCode: ProductData?.productCode || '',
      startTimeLocal: `${start}+00:00:00`,
      endTimeLocal: `${end}+23:59:00`,
      count: 10000,
      offset: 0,
    };
    if (!filter.productCode) {
      return
    }
    loader(true)
    const DataConvertor = (data, type = '') => {
      if (type == 'procharter') {
        return data?.map((item, index) => {
          return { ...item, startTimeLocal: item?.DateTimeFrom, endTimeLocal: item?.DateTimeTo, priceOptions: item?.priceOptions?.map((item) => ({ ...item, price: item?.Price, label: 'adult' })), endTime: item?.DateTimeTo, startTime: item?.DateTimeFrom, seatsAvailable: TotalDataCalculator(item?.priceOptions, 'Available'), seats: TotalDataCalculator(item?.priceOptions, 'Quantity') }
        })
      } else {
        return data?.data?.map((item, index) => {
          return { ...item, startTimeLocal: `${item?.date}`, endTimeLocal: `${item?.end_date}`, priceOptions: [{ price: item?.tour_price, label: 'adult' }], endTime: item?.end_date, startTime: item?.date, seatsAvailable: item?.available_seats, seats: item?.allocated_seats }
        })
      }
    }
    ApiClient.get(`api/product/availablity`, filter).then((res) => {
      if (res.success) {
        let data = res?.data;
        if (ProductData?.booking_platform == 'procharter' || ProductData?.posId?.booking_platform == 'procharter') {
          data = DataConvertor(data, 'procharter')
        }
        setAvailability(data || []);
      }
      loader(false)
    });
  };

  useEffect(() => {
    fetchAvailability(currentMonth, currentYear);
  }, [currentMonth, currentYear]);

  const getAvailabilityForDate = (day) => {
    const date = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    const availability = Availablity.find(
      (item) => item?.startTime?.startsWith(date)
    );
    return availability || {};
  };

  const QuantityAddHandler = (data, index, type = '') => {
    let label = data?.label;
    const PriceOptions = CurrentProductData?.priceOptions;

    // Get the initial quantity or default to 0 if it doesn't exist
    const InitialQuantity = PriceOptions[index]['quantity'] || 0;

    // Determine the adjustment based on the type
    const adjustment = type === 'decrease' ? -1 : 1;

    // Update the quantity
    PriceOptions[index]['quantity'] = InitialQuantity + adjustment;

    // Set the updated data in state
    setCurrentProductData({
      ...CurrentProductData,
      priceOptions: PriceOptions,
    });
  };



  const PriceCalculator = () => {
    const PricData = CurrentProductData?.priceOptions;
    let PriceTotal = 0;
    PricData?.map((item) => {
      PriceTotal += Number(item?.quantity * item?.price);
    })
    return PriceTotal
  }

  const AddToCartHandler = () => {
    // Retrieve cart data from local storage or initialize as an empty array if null
    const CardData = JSON.parse(localStorage.getItem('cart_data')) || [];

    // Create the date string for comparison
    const currentDate = `${currentMonth}-${selectedDate}-${currentYear}`;

    // Find if the product already exists in the cart for the same product code and date
    const existingProductIndex = CardData.findIndex(
      (item) => item?.productCode === ProductData?.productCode && item?.date === currentDate
    );

    if (existingProductIndex !== -1) {
      // Product already exists in the cart for the same date, update the quantity
      const existingProduct = CardData[existingProductIndex];

      // Update each price option's quantity based on CurrentProductData
      existingProduct.priceOptions = existingProduct.priceOptions.map((option, index) => {
        // Check if CurrentProductData has a quantity for this option
        const newQuantity = CurrentProductData.priceOptions[index]?.quantity;
        return {
          ...option,
          quantity: newQuantity ? newQuantity : option.quantity || 0,
        };
      });

      // Update the existing product data in the cart array
      CardData[existingProductIndex] = { ...existingProduct, date: currentDate };
    } else {
      // Product does not exist in the cart for the same date, add it
      CardData.push({ ...CurrentProductData, date: currentDate });
    }

    // Save the updated cart data back to local storage
    localStorage.setItem('cart_data', JSON.stringify(CardData));
    setCartData(CardData)
    setIsOpen(false);
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg w-full mx-auto">
      <h2 className="text-center text-2xl font-bold mb-6">{ProductData?.name}</h2>

      {/* Year Selector */}
      <div className="flex justify-center items-center mb-4">

        <span className="text-xl font-semibold">{currentYear}</span>

      </div>

      <div className="flex gap-2 items-center">

        <button onClick={() => handleYearChange(-1)} className="text-gray-700 hover:text-purple-600 mb-0 text-3xl">  <MdKeyboardArrowLeft /></button>




        {/* Month Selector */}
        <div className="flex justify-between bg-gray-100 items-center overflow-x-auto rounded-lg ">
          {["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map((month, idx) => (
            <button
              key={month}
              onClick={() => handleMonthChange(idx + 1)}
              className={`px-4 py-2 ${currentMonth === idx + 1 ? "bg-purple-600 text-white" : "text-gray-700"} rounded-lg focus:outline-none`}
            >
              {month} {currentYear}
            </button>
          ))}
        </div>




        <button onClick={() => handleYearChange(1)} className="text-gray-700 hover:text-purple-600 mb-0 text-3xl"> <MdKeyboardArrowRight /></button>





      </div>




      {/* Calendar Grid */}
      <div className="grid grid-cols-7 gap-4 mb-6 mt-6">
        {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((day) => (

          <div key={day} className="text-center font-medium text-gray-500">{day}</div>
        ))}
        {emptyCells.map((_, index) => <div key={`empty-${index}`} />)}
        {days.map((day) => {
          const availability = getAvailabilityForDate(day);
          const available = availability.seatsAvailable || 0;
          const price = availability.priceOptions?.[0]?.price || ProductData?.advertisedPrice;

          return (
            <div
              key={day}
              onClick={() => !available ? null : handleDateSelect(day)}
              className={`p-4 border rounded-lg ${selectedDate === day ? "border-purple-600" : "border-gray-300"} flex flex-col  cursor-pointer hover:bg-purple-50`}
            >
              <span className="text-xs bg-primary px-2 py-1 mb-2  rounded-lg text-white text-center font-bold w-10">{day}</span>

              <div className="text-center flex flex-col gap-2">
                <span className="text-md text-black font-semibold ">AUD ${price}</span>
                <span className="text-xs text-gray-500">{available} spaces left</span>
                <button className={`mt-2 px-4 py-1 text-xs font-semibold rounded-md ${selectedDate === day ? "bg-primary text-white" : "bg-white border border-purple-600 text-purple-600"}`} disabled={!available}>
                  {selectedDate === day ? "CHOSEN" : !available ? 'Not Available' : "Choose"}
                </button>
              </div>
            </div>

          );
        })}
      </div>

      {/* Booking Details */}
      {selectedDate && (
        <div className="border-2 border-primary rounded-xl w-full">

          <div className="flex gap-2 items-center justify-between">


            <div className="flex flex-col gap-4 lg:gap-6 xl:gap-8 p-4 ">


              <div>
                <h2 className="text-gray-900 text-2xl font-bold leading-[34px] mb-2">
                  {ProductData?.name}
                </h2>


                <div className="flex mt-2 gap-4 lg:gap-6 xl:gap-8 items-center flex-wrap">
                  <div className="flex gap-2 items-center">
                    <span><IoLocationOutline />
                    </span>
                    <p className="text-[14px]">{ProductData?.country?.name},{ProductData?.region?.name}</p>
                  </div>

                  <div className="flex gap-2 items-center">
                    <span><LiaFlag />
                    </span>
                    <p className="text-[14px]">{ProductData?.locationAddress?.country},{ProductData?.locationAddress?.state}</p>
                  </div>

                  <div className="flex gap-2 items-center">
                    <span><LuCalendarDays />
                    </span>
                    <p className="text-[14px]">{`${currentMonth}-${selectedDate}-${currentYear}`}</p>
                  </div>


                  <div className="flex gap-2 items-center">
                    <span><FaStar className="text-yellow-500" />
                    </span>
                    <p className="text-[14px]">4.9 (8 reviews)</p>
                  </div>

                </div>
              </div>

              <div className="flex gap-4 lg:gap-6 xl:gap-8 items-center flex-wrap">




                {CurrentProductData?.priceOptions?.map((item, index) => (<div className="flex items-center gap-4 lg:gap-6">
                  <div>
                    <p className="text-gray-900 text-base font-medium">{item?.label}</p>
                    <p className="text-gray-500 text-sm">AUD ${item?.price}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <button className="w-6 h-6 border border-gray-400 rounded text-center" onClick={() => QuantityAddHandler(item, index, 'decrease')} disabled={!item?.quantity}>-</button>
                    <span className="text-gray-900 font-semibold">{item?.quantity || 0}</span>
                    <button className="w-6 h-6 border border-purple-600 rounded text-center" onClick={() => QuantityAddHandler(item, index, 'increase')}>+</button>
                  </div>
                </div>))}


                {/* 
                <div className="flex items-center gap-4 lg:gap-6">
                  <div>
                    <p className="text-gray-900 text-base font-medium">Children</p>
                    <p className="text-gray-500 text-sm">AUD $450.00</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <button className="w-6 h-6 border border-gray-400 rounded text-center">-</button>
                    <span className="text-gray-900 font-semibold">0</span>
                    <button className="w-6 h-6 border border-purple-600 rounded text-center">+</button>
                  </div>
                </div> */}
              </div>
            </div>

            <div className=" flex flex-col items-center w-52 shrink-0 py-6 justify-between border-l-2 border-primary px-4">
              <span className="text-gray-500 ">AUD ${parseFloat(PriceCalculator() || 0).toFixed(2)}</span>
              {ProductState?.cart ? <button className=" mt-4 px-4 py-2 bg-primary text-white font-semibold rounded-md disabled:cursor-not-allowed disabled:bg-gray-400" onClick={AddToCartHandler} disabled={!PriceCalculator()}>Add to Wishlist</button> :
                <button className=" mt-4 px-4 py-2 bg-primary text-white font-semibold rounded-md disabled:cursor-not-allowed disabled:bg-gray-400" disabled={!PriceCalculator()}>Book it</button>}
            </div>

          </div>


        </div>
      )}











    </div>
  );
};

export default BookingCalendar;
