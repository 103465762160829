import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Html from "./Html";
import environment from "../../../environment";
import { useEffect, useState } from "react";
import { sidebar_base } from "../../../actions/sidebar";
const Sidebar = ({ isOpen }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useNavigate();
  const sidebarCollapseData = useSelector(state => state.sidebar);
  const [CollapsedState, setCollapsedState] = useState(sidebarCollapseData?.value);
  useEffect(() => {
    setCollapsedState(sidebarCollapseData?.value)
  }, [sidebarCollapseData?.value])
  const menus = {
    users: ["roles", "users"],
    sales: ["sales", 'orders', "saleData", "sale/retrive", "sales/refunds", 'dynamicprice', "customer"],
    catalogue: ["types", "categories", "category/Reseller"],
    emailSetting: [
      "email",
    ],
    customRelation: ["omni-channels", '/whatsapp/template', , '/omni-channel-setting/integration', '/chatbot', '/omni-channel-setting/widget', 'gmail', "reviews", "review-dashboard", '/wavier/settings', 'waiver', 'waidatabase', 'review/'],
    reports: ["transactions", "report/sales", "report/affiliates", "report/customers"],
    costingTemp: ['costingtour', 'costing', '/costing/template'],
    productdashboard: [
      "product-dashboard",
      "products",
      'product',
      '/cancel/policy',
      "product-data",
      '/content/settings',
      "product/detail",
      '/reseller/updates',
      "product/retrivable",
      'costingtour',
      'costing',
      '/content/detail'

    ],
    socialMedia: [
      '/social/media'
    ],
    templates: [
      '/gmail/', "whatsapp/template", "whtasapp/template/add"
    ],
    whatsapp: ["whatsapp/template", "whtasapp/template/add"],
    customers: ["customers", "customer-data", 'customerdetails',],
    waivers: ['/wavier/settings', 'waiver', 'waidatabase'],
    dynamicPricing: ["dynamicprice"],
    reviews: ["reviews", "review-dashboard", 'review/'],
    plan: ["plans", "card"],
    customer: ["customer"],
    marketing: ["marketing", 'coupons', 'google', 'forecasting',
      '/social/media', "request", "affiliate", "messages", 'banner', 'affiliateInbox', "default-commission", "current-affiliate", "products/banners"],
    financial: ["financial", "accounting", '/accounting/profit/date', '/accounting/profit/month'],
    googletag: ['/google/tags'],
    settings: [
      "two-factor-auth",
      '/booking/setting',
      'costing',
      'partner',
      '/email/addedit/',
      "company",
      "activeplan",
      '/omni-channel-setting/social/media',
      "plans",
      '/omni-channel-setting/copy',
      "card",
      '/email/templates',
      '/email/list',
      "users",
      "roles",
      "product",
      "pos",
      "api/",
      "marketing-analytics",
      "reviewStep",
      "roles",
      '/omni-channel-setting/google/tag',
      "users",
      "marketing-software"
    ],
    omni: ["omni-channels", '/whatsapp/template', , '/omni-channel-setting/integration', 'chatbot', '/omni-channel-setting/widget', 'gmail'],
    affiliates: ["request", "affiliate", "messages", 'banner', 'affiliateInbox', "default-commission", "current-affiliate", "products/banners"],
    crm: ["reseller", '/invoice-commission', "reseller-data", 'crm', '/content/detail', '/invoice/template', '/inovoice/template/edit', '/reseller-invoices', 'supplier-invoice-detail'],
    contents: ['/reseller/updates', '/content/settings'],
    api: [
      "product",
      "pos",
      "api/",
      "marketing-analytics",
      "reviewStep",
      "review-connected",
    ],
    InvoicesRes: ['/invoice/template', '/inovoice/template/edit', 'reseller-invoices', 'supplier-invoice-detai'],
    templatescrm: ['crm'],
    geo: ["continents", "countries", "regions", "cities"],
  };

  useEffect(() => {
    let value = false;
    Object.keys(menus).map((item) => {
      if (tabclass(item)) {
        value = true
      }
    })
    dispatch(sidebar_base({ value: value }))
  }, [])
  const ListItemLink = ({ to, type = "link", disabled = false, ...rest }) => {
    let url = location.href;
    const host = location.host;
    url = url?.split(host)[1];
    return (
      <>
        {type == "link" ? (
          <li className={`nav-item ${url.includes(to) ? "active" : ""}`}>
            <Link
              to={to}
              {...rest}
              className={`nav-link hoverclass ${disabled ? "disabled" : ""}`}
              disabled={disabled}
            />
          </li>
        ) : (
          <li
            className={`nav-item main ${url.includes(to) ? "active" : ""}`}
            {...rest}></li>
        )}
      </>
    );
  };

  const matchUrl = (to, exact = false) => {
    let url = location.href;
    const host = location.host;
    url = url?.split(host)[1];
    if (exact) return url == to;
    if (!exact) return url.includes(to);
  };

  const tabclass = (tab) => {
    let url = location.href;
    let value = false;
    menus[tab].map((itm) => {
      if ((url.includes(itm) && CollapsedState) || sidebarCollapseData?.allState) value = true;
    });
    // alert(CollapsedState)

    return value;
  };

  const urlAllow = (url) => {
    if (user?.subRole?.id == environment.SubRolePartner) {
      return true
    }
    let permissions = user?.subRole && user?.subRole?.permissions && user?.subRole?.permissions[0];
    let arr = url?.split(",");
    let value = false;
    arr.map((itm) => {
      if (permissions?.[itm]) value = true;
    });
    if (user?.subRole?.id || user?.subRole?._id) {
      return value;
    } else {
      return true;
    }
  };

  const route = (p) => {
    history(p);
  };

  const scrollToId = (id) => {
    let data = id
    if (id.includes('/email') || id.includes('/emails')) {
      data = '/email/list'
    }
    if (id == '/omni-channel-setting/copy') {
      data = '/company'
    }
    if (id == '/plans') {
      data = '/activeplan'
    }
    const element = document.getElementById(data);
    if (element) {
      element.scrollIntoView({ behavior: 'auto' });
    } else {
    }
  };

  useEffect(() => {
    try {
      scrollToId(window.location.pathname == '/accounting/bills' ? "/accounting/invoices" : window.location.pathname.includes('google/tag') ? '/marketing/google-analytics' : window.location.pathname)
    } catch {
    }
  }, [window.location.pathname]);

  return (
    <>
      <Html
        route={route}
        CollapsedState={CollapsedState}
        setCollapsedState={setCollapsedState}
        scrollToId={scrollToId}
        tabclass={tabclass}
        ListItemLink={ListItemLink}
        urlAllow={urlAllow}
        isOpen={isOpen}
      />
    </>
  );
};

export default Sidebar;
