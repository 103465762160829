import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const LineCurveGraph = () => {
  const user = useSelector((state) => state.user);

  const accountOverviewData = [
    {
      month: "Jan",
      totalImpressions: 10000,
      clicks: 200,
      conversions: 50,
      percentage: "2.1%",
    },
    {
      month: "Feb",
      totalImpressions: 15000,
      clicks: 300,
      conversions: 60,
      percentage: "2.1%",
    },
    {
      month: "Mar",
      totalImpressions: 12000,
      clicks: 250,
      conversions: 40,
      percentage: "2.1%",
    },
    {
      month: "Apr",
      totalImpressions: 18000,
      clicks: 350,
      conversions: 70,
      percentage: "2.1%",
    },
    {
      month: "May",
      totalImpressions: 25000,
      clicks: 500,
      conversions: 100,
      percentage: "2.5%",
    },
    {
      month: "Jun",
      totalImpressions: 30000,
      clicks: 600,
      conversions: 120,
      percentage: "2.1%",
    },
    {
      month: "Jul",
      totalImpressions: 45000,
      clicks: 800,
      conversions: 150,
      percentage: "2.1%",
    },
    {
      month: "Aug",
      totalImpressions: 50000,
      clicks: 900,
      conversions: 200,
      percentage: "2.5%",
    },
    {
      month: "Sep",
      totalImpressions: 60000,
      clicks: 1200,
      conversions: 250,
      percentage: "1%",
    },
    {
      month: "Oct",
      totalImpressions: 45000,
      clicks: 700,
      conversions: 140,
      percentage: "2.1%",
    },
    {
      month: "Nov",
      totalImpressions: 30000,
      clicks: 500,
      conversions: 110,
      percentage: "2.1%",
    },
    {
      month: "Dec",
      totalImpressions: 20000,
      clicks: 300,
      conversions: 80,
      percentage: "2.1%",
    },
  ];

  let updatedOption = {
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        const index = params[0].dataIndex;
        const monthData = accountOverviewData[index];
        return `
          <div style="color: #000;">
            <strong style="color: #000;">Data for ${monthData.month}</strong><br/>
            <span style="color: #0E9F6E;">Total Impressions: </span>
            <span style="color: #333;">${monthData.totalImpressions} (${monthData.percentage})</span><br/>
            <span style="color: #FF5733;">Clicks: </span>
            <span style="color: #333;">${monthData.clicks}</span><br/>
            <span style="color: #C70039;">Conversions: </span>
            <span style="color: #333;">${monthData.conversions}</span>
          </div>`;
      },
      backgroundColor: "#fff",
      borderColor: "#fff",
      borderWidth: 1,
      padding: 10,
      textStyle: {
        color: "#333",
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: accountOverviewData.map((item) => item.month),
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => `${value / 1000}k`,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    series: [
      {
        name: "Account Overview",
        type: "line",
        smooth: true,
        data: accountOverviewData.map((item) => item.totalImpressions),
        itemStyle: {
          color: "#0E9F6E",
        },
        lineStyle: {
          color: "#0E9F6E",
          width: 2,
        },
        symbol: "circle",
        symbolSize: 8,
        emphasis: {
          itemStyle: {
            borderWidth: 2,
            borderColor: "#FFFFFF",
          },
        },
        areaStyle: {
          color: "rgba(14, 201, 141, 0.2)",
        },
      },
    ],
  };

  return (
    <div className="pt-10 px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default LineCurveGraph;
