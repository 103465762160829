const Data = {
    'dashboard': ['/dashboard'],
    'omni-channel': ['/chatbot', '/omni-channels/live-chat', '/whatsapp/template', '/gmail/template', '/omni-channel-setting/widget', '/omni-channel-setting/copy'],
    'reviews': ['/review-dashboard', '/reviews', '/reviews/settings', '/api/review-connected'],
    'waviers': ['/waiver/list', '/wavier/settings', '/waidatabase'],
    'sales': ['/sales', '/customers', '/sales-data', '/sales/retrievable', '/sales/refunds'],
    'dynamic-pricing': ['/dynamicprice-list'],
    'products': ['/product-dashboard', '/product-data', '/products/retrivable', '/cancel/policy', '/costing/template', '/costingtour/template', '/pos'],
    'content-updates': ['/reseller/updates', '/content/settings'],
    'resellers': ['/reseller-dashboard', '/reseller-data'],
    'invoices': ['/reseller-invoices', '/invoice/template'],
    'contracts': ['/crm'],
    'profitablity': ['/accounting/dashboard', '/accounting/transactions'],
    'marketing': ['/marketing/google-analytics', '/marketing-analytics', '/api/accounting'],
    'manage-google-tags': ['/google/tags'],
    'affiliates': ['/affiliate', '/current-affiliate', '/request', '/general/banners', '/products/banners', '/default-commission', '/affiliateInbox', '/transactions', '/report/sales', '/report/affiliates', '/report/customers'],
    'social-media-marketing': ['/social/media']
};




const UrlValidator = (url, PlanData) => {
    const FeatureListing = {}
    const FeaturesList = Object.keys(Data)?.map((itm) => {
        Data[itm]?.map((itmo) => {
            FeatureListing[itmo] = itm
        })
    });
    let ModuleName = FeatureListing[url];
    if (ModuleName) {
        if (PlanData?.includes(ModuleName)) {
            return true
        } else {
            false
        }
    } else {
        return true
    }
}

const SubscriptionModel = { UrlValidator };
export default SubscriptionModel