import React, { useState, useEffect, Fragment } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/global/layout";
import methodModel from "../../methods/methods";
import Breadcrumb from "../../components/common/Breadcrumb";
import SelectDropdown from "../../components/common/SelectDropdown";
import { FiUpload } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";

const ImportCsv = () => {

  const [Instructions, setInstructions] = useState("");
  const [FullPOSData, setFullPOSData] = useState([]);
  const SelectedId = methodModel.getPrams("id");
  const SelectedPlatfrom = methodModel.getPrams("platform");
  const [tab, setTab] = useState("form");
  const [form, setform] = useState({ id: "", name: "", apiKey: "", url: "", startDate: '' });

  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const formValidation = [{ key: "name", required: true }];

  const [CSVForm, setCSVForm] = useState({ name: '', csvName: '', overwrite_data: false })
  const [AllKeys, setAllKeys] = useState([]);
  const [MappedKeys, setMappedKeys] = useState([]);
  const [UnMappedKeys, setUnMappedKeys] = useState([]);
  const [fileKeys, setFileKeys] = useState([]);

  const updateFile = (i, key = '', value) => {
    let arr = fileKeys
    arr[i][key] = value
    setFileKeys([...arr])
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!CSVForm.csvName || !CSVForm.name) return

    // loader(true);
    const payload = {
      bookingPlatform: CSVForm?.name,
      "fileName": CSVForm?.csvName,
      overwrite_data: CSVForm?.overwrite_data,
      mapedKeys: [...fileKeys]
    }
    loader(true)
    ApiClient.post(`api/upload/booking/csv/mapping`, payload).then(res => {
      if (res.success) {
        history('/pos')
        // setCSVForm({ csvName: '', overwrite_data: false })
      }
      loader(false)
    })
  };


  useEffect(() => {
    if (SelectedPlatfrom) {
      setCSVForm({ name: SelectedPlatfrom, csvName: '', overwrite_data: false })
    }
  }, [SelectedPlatfrom]);

  const handlePOS = (e) => {
    if (e) {
      if (e == "rezdy") {
        setform({ ...form, name: e, url: "https://api.rezdy.com/v1" });
      } else if (e == "hakatours") {
        setform({ ...form, name: e, url: "https://api-production.hakatours.com" });

      } else if (e == "peekpro") {
        setform({ ...form, name: e, url: "https://octo.peek.com/integrations" });
      } else if (e == "checkfront") {
        setform({ ...form, name: e, url: "https://drop-bears-kgari-adventures.checkfront.com" });
      } else if (e == "kounta") {
        setform({ ...form, name: e, url: "https://api.lightspeedapp.com" });
      } else {
        setform({ ...form, name: e, url: "" });
      }
    } else {
      setform({ ...form, name: "", url: "" });
    }
  };

  const [item, setitem] = useState([]);
  const getData2 = () => {
    let url = "api/bookingsystem/listing";
    const UserDomain = user?.email?.split("@");
    let filter = { status: "active", domains: UserDomain[1] || "" };
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        setFullPOSData(res?.data);

        setitem(
          res.data.map((itm) => {
            return {
              ...itm,
              id: itm?.platform,
              name: itm.name || itm.platform,
            };
          })
        );
        if (SelectedId) {
          const SelectedData = res?.data?.find((itm) => {
            return itm?.id == SelectedId;
          });
          handlePOS(SelectedData?.platform);
          setInstructions(SelectedData?.instructions);
        }

        if (SelectedPlatfrom) {
          const SelectedData = res?.data?.find((itm) => {
            return itm?.platform == SelectedPlatfrom;
          });
          handlePOS(SelectedData?.platform);
          setInstructions(SelectedData?.instructions);
        }
      }
    });
  };

  useEffect(() => {
    getData2();
  }, []);

  const uploadCSVFile = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    setSubmitted(false)
    ApiClient.postFormData(`api/upload/booking/csv?bookingPlatform=${CSVForm?.name}`, {
      file: file,
    }).then((res) => {
      if (res.filePath) {

        setCSVForm({ ...CSVForm, csvName: res?.filePath });
        setUnMappedKeys(res?.fileUnmappedKeys?.map((item) => ({ rezdyKeyName: '', dazhboardsKeyName: '', label: item })))
        let allKeys = [
          ...res?.keysNotMapped?.map((item) => ({ id: item, name: item })),
          ...res?.alreadyMappedKeys?.map((item) => ({ id: item.dazhboardsKeyName, name: item.dazhboardsKeyName }))
        ]

        setAllKeys(allKeys);
        setMappedKeys(res?.alreadyMappedKeys)
        const AllreadyFileKeysMap = res.alreadyMappedKeys.filter(itm => ({
          rezdyKeyName: itm.rezdyKeyName,
          dazhboardsKeyName: itm.dazhboardsKeyName,
          maped: itm.dazhboardsKeyName ? true : false,
          order: itm.dazhboardsKeyName ? 1 : 2,
          duplicate: res.alreadyMappedKeys.map(itm => itm.rezdyKeyName).filter(fitm => fitm == itm.rezdyKeyName).length > 1 ? true : false
        }))
        res?.fileUnmappedKeys?.map((item) => {
          AllreadyFileKeysMap.push({
            rezdyKeyName: item,
            dazhboardsKeyName: "",
            maped: false,
            order: 2,
            duplicate: AllreadyFileKeysMap.map(itm => itm.rezdyKeyName)?.filter(fitm => fitm == item).length > 1 ? true : false
          })
        })
        let fileKey = AllreadyFileKeysMap;

        fileKey = fileKey.sort((a, b) => {
          return a.order - b.order
        })


        setFileKeys(fileKey)
      }
      loader(false);
    });
  };

  const dazhList = (key) => {
    let arr = AllKeys
    let files = fileKeys.map(itm => itm.dazhboardsKeyName).filter(itm => itm)
    arr = arr.filter(itm => (!files.includes(itm.id) || itm.id == key))
    return arr
  }

  const overideAll = (overide) => {
    let arr = fileKeys
    arr = arr.map(itm => ({ ...itm, overide: overide }))
    setFileKeys([...arr])
  }

  return (
    <>
      <Layout>
        {tab == "form" ? (
          <>
            <Breadcrumb
              links={[
                {
                  name: "Booking System",
                  link: "/pos",
                },
              ]}
              currentPage={"Import CSV"}
            />
            <h5 className="text-typo my-6 text-xl font-semibold">Import CSV</h5>
            <div className="flex items-start gap-6">
              <div className="max-w-[1020px] flex-1">
                <div className="shadow-box border !border-grey bg-white rounded-large">
                  <form onSubmit={handleSubmit}>
                    <div className="gap-y-6 grid grid-cols-1 p-6">
                      <div>
                        <label className="text-[#75757A] text-sm font-normal !mb-3">
                          Select Res System
                        </label>
                        <SelectDropdown
                          isSingle={true}
                          id="statusDropdown"
                          displayValue="name"
                          placeholder="Select Booking System"
                          intialValue={CSVForm?.name}
                          result={(e) => {
                            setCSVForm({ name: e.value })
                          }}
                          options={item}
                          className="w-full"
                        />
                      </div>

                      {CSVForm.name ? <>
                        {CSVForm?.csvName ? <>
                          <div className="justify-center relative flex m-3">

                            <img src="https://png.pngtree.com/png-clipart/20190630/original/pngtree-csv-file-document-icon-png-image_4177027.jpg" className="w-36 h-36" />
                            <p className="absolute right-0 text-red-600 text-[20px]" onClick={e => { setAllKeys([]); setUnMappedKeys([]); setMappedKeys([]); setCSVForm({ ...CSVForm, csvName: '', overwrite_data: false }); }}><IoIosClose /></p>
                          </div>
                        </> :
                          <div
                            className="flex items-center justify-center w-full h-56 border-[1.5px] border-grey rounded-large mt-4 cursor-pointer"
                          >
                            <label
                              htmlFor="dropzone-file"
                              className="flex cursor-pointer flex-col items-center justify-center w-full h-full"
                            >
                              <div className="flex flex-col items-center justify-center gap-2 pt-5 pb-6 px-4 text-center">
                                <FiUpload className="text-xl text-[#1C1B1F]" />
                                <p className="text-sm font-light text-[#585858]">
                                  Drag & drop files here, or click to select files
                                </p>
                                <p className="text-xs text-[#8F8F8F] font-light">
                                  Supported File Types: .csv
                                </p>
                              </div>
                              <input
                                id="dropzone-file"
                                type="file"
                                multiple
                                className="hidden"
                                onChange={(e) => uploadCSVFile(e)}
                              />
                            </label>
                          </div>}

                        {submitted && !CSVForm?.csvName && <p className="text-sm text-red-400 p-2">File  is required</p>}

                        {CSVForm?.name && <div className="flex gap-1.5 items-start my-5">
                          <input type="checkbox" className="mt-0.5 h-4 w-4 cursor-pointer shrink-0 rounded-[4px] border border-[#3C3E49A3] !text-primary" id="exampleCheck1" checked={CSVForm?.overwrite_data} onChange={e => setCSVForm(prev => ({ ...prev, overwrite_data: e.target.checked }))} />
                          <label className="text-sm font-medium text-[#344054]" for="exampleCheck1">
                            <p className="font-normal">Do you want to Override?</p>
                          </label>
                        </div>}
                      </> : <></>}


                      <div class="relative">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <thead class="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              {CSVForm?.overwrite_data ? <>
                                <th scope="col" class="px-6 py-3">
                                  <div class="inline-flex gap-1.5 items-start">
                                    <input type="checkbox"
                                      checked={fileKeys.find(itm => !itm.overide) ? false : true}
                                      onChange={e => {
                                        overideAll(e.target.checked)
                                      }}
                                      class="mt-0.5 h-4 w-4 cursor-pointer shrink-0 rounded-[4px] border border-[#3C3E49A3] !text-primary" id="exampleCheck1" /><label class="text-sm font-medium text-[#344054]" for="exampleCheck1"></label></div>
                                  Override
                                </th>
                              </> : <></>}

                              <th scope="col" class="px-6 py-3">
                                Columns in your file

                              </th>
                              <th scope="col" class="px-6 py-3">
                                Columns in Dazhboards

                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fileKeys.map((itm, i) => {
                              return <Fragment key={i}>

                                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                  {CSVForm?.overwrite_data ? <>
                                    <td class="px-6 py-4">
                                      <div class="flex gap-1.5 items-start my-4">
                                        <input type="checkbox"
                                          checked={itm.overide ? true : false}
                                          onChange={e => {
                                            updateFile(i, 'overide', e.target.checked)
                                          }}
                                          class="mt-0.5 h-4 w-4 cursor-pointer shrink-0 rounded-[4px] border border-[#3C3E49A3] !text-primary" id="exampleCheck1" /><label class="text-sm font-medium text-[#344054]" for="exampleCheck1"></label></div>
                                    </td>
                                  </> : <></>}

                                  <td class="px-6 py-4">
                                    <div className="flex gap-3">
                                      {itm.rezdyKeyName} {itm.duplicate ? <span className="text-red-400">(Duplicate)</span> : <></>}
                                      <span class="material-symbols-outlined ml-auto">arrow_forward</span>
                                    </div>
                                  </td>
                                  <td class="px-6 py-4">
                                    <div className="flex gap-2">
                                      <SelectDropdown
                                        isSingle={true}
                                        id="statusDropdown"
                                        displayValue="name"
                                        placeholder="Don't Import"
                                        intialValue={itm?.dazhboardsKeyName}
                                        result={(e) => {
                                          updateFile(i, 'dazhboardsKeyName', e.value)
                                        }}
                                        options={[
                                          { id: '', name: `Don't Import` },
                                          ...dazhList(itm?.dazhboardsKeyName)
                                        ]}
                                        disabled={itm.maped}
                                        theme="search"
                                        className="w-full"
                                      />

                                      {itm?.dazhboardsKeyName ? <>
                                        <span class="material-symbols-outlined text-green-400">check</span>
                                      </> : <>
                                        <span class="material-symbols-outlined">info</span>
                                      </>}


                                    </div>
                                  </td>
                                </tr>
                              </Fragment>

                            })}

                          </tbody>
                        </table>
                      </div>


                    </div>
                    <div className="px-6 !py-4 border-t border-[#EAECF0] flex justify-end items-center !gap-3">
                      <Link
                        to="/pos"
                        className="!px-4 text-typo hover:!text-typo hover:no-underline text-sm font-medium py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
                        Back
                      </Link>
                      <button
                        type="submit"
                        className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                        Save
                      </button>
                    </div>
                  </form >
                </div >

              </div >
            </div >
          </>
        ) : (
          <>
          </>
        )}
      </Layout >
    </>
  );
};

export default ImportCsv;
