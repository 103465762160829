/*
 * @file: user.js
 * @description: Reducers and actions for store/manipulate user's  data
 * @date: 28.11.2019
 * @author: ANil
 */

/******** Reducers ********/

const initialState = ['affiliates', 'manage-google-tags', 'marketing', 'profitablity', 'contracts', 'invoices', 'resellers', 'content-updates', 'products', 'dynamic-pricing', 'sales', 'waviers', 'reviews', 'omni-channel', 'dashboard', 'login', 'signup', 'social-media-marketing']

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'Subscription':
            return action.data?.filter((item) => item)

        default:
            return state
    }
}
