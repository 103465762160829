import React, { useState } from "react";
import Layout from "../../components/global/layout";
import SelectDropdown from "../../components/common/SelectDropdown";
import { RxReset } from "react-icons/rx";
import DateRangePicker from "../../components/common/DateRangePicker";
import datepipeModel from "../../models/datepipemodel";
import { BsTrash3 } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { MdAccessTime } from "react-icons/md";
import PricingCurveLineGraph from "./GraphCharts/PricingCurveLineFraph";

const Pricing = () => {
  const CostinDropOptions = [
    { id: "per-pax", name: "Per Pax" },
    { id: "per-tour", name: "Per Tour" },
  ];
  const products = [
    { id: "per-pax", name: "Product 1" },
    { id: "per-tour", name: "Product 2" },
    { id: "per-tour", name: "Product 3" },
  ];

  const options = [
    { id: "Line", name: "Line Chart" },
    { id: "Bar", name: "Bar Chart" },
  ];
  const months = [
    { id: "month", name: "January" },
    { id: "month", name: "February" },
  ];
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    sortBy: "dateCreated desc",
    search: "",
    posId: "",
    status: "",
    productCode: "",
    category: [],
    travelStartDate: "",
    travelEndDate: "",
    reseller: "",
    startDate: datepipeModel.datetostring(new Date()),
    endDate: datepipeModel.datetostring(new Date()),
    compare: false,
    compareStart: "",
    compareEnd: "",
  });
  const CostingDefault = { label: "", value: "", cal_type: "" };
  const [CostingSubmitted, setCostingSubmitted] = useState(false);

  const [form, setform] = useState({
    total_pax: "",
    total_cars: "",
    total_tour_price: "",
    variable_costs: [{ ...CostingDefault }],
    fixed_costs: [{ ...CostingDefault }],
  });

  const HandleAddCosting = (type = "variable_costs") => {
    const Data = form[type];
    setform({ ...form, [type]: [...Data, CostingDefault] });
  };
  const RemoveCosting = (index, type) => {
    const Data = form[type];

    setform((prev) => {
      return {
        ...prev,
        [type]: Data.filter((item, indexo) => indexo != index),
      };
    });
  };

  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
    };

    if (
      datepipeModel.datetostring(e.startDate) ===
      datepipeModel.datetostring(e.endDate)
    ) {
      setFilter({ ...filters, ...f });
    } else {
      setFilter({ ...filters, ...f });
      dataFilter({ ...f });
    }
  };

  return (
    <Layout>
      <div className="mb-6">
        <h4 className="text-2xl font-semibold text-[#3C3E49] mb-1">
          Data-Driven Sales Forecasting
        </h4>
        <p className="text-sm font-normal text-[#75757A]">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi,
          doloremque!
        </p>
      </div>
      <div className="grow mb-8 flex flex-wrap items-center justify-end gap-3">
        <div class="w-full max-w-[300px] !p-1 bg-[#F1F3F9] border border-gray-200 rounded-extra_large flex items-center gap-2">
          <label class="flex-1 mb-0 flex items-center justify-center gap-2 rounded-large text-sm font-medium py-2 px-3 focus:outline-none text-[#1D2433A6]">
            Financial year
          </label>
          <label class="flex-1 mb-0 flex items-center justify-center gap-2 rounded-large text-sm font-medium py-2 px-3 focus:outline-none text-typo bg-white cursor-pointer shadow-sm border-2 border-primary">
            Calendar year
          </label>
        </div>
        <SelectDropdown
          displayValue="name"
          placeholder="All Products"
          options={products}
          noDefault={true}
        />
        <DateRangePicker
          disabled={filters.yearType === "calendar"}
          title={
            filters.yearType === "calendar"
              ? ""
              : "Change Data by Calendar Year to change dates"
          }
          value={{
            startDate: filters.startDate,
            endDate: filters.endDate,
            compare: filters.compare,
            compareStart: filters.compareStart,
            compareEnd: filters.compareEnd,
          }}
          isCompare={true}
          onChange={(e) => onRangeChange(e)}
          fullWidth={false}
        />
        <button
          type="button"
          className="bg-white !px-4 text-sm font-normal text-primary h-10 flex items-center justify-center gap-2 rounded-extra_large shadow-btn hover:bg-primary hover:text-white border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
        >
          <RxReset className="text-xl" />
          Reset
        </button>
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large w-full mb-8">
        <div className="flex justify-end p-5 gap-2 flex-wrap">
          <SelectDropdown
            displayValue="name"
            placeholder="Select Chart"
            options={options}
            noDefault={true}
          />
          <SelectDropdown
            displayValue="name"
            placeholder="This Month"
            options={months}
            noDefault={true}
          />
        </div>
        <div className="relative">
          <PricingCurveLineGraph />
          <ul className="flex flex-col absolute space-y-5 top-10 right-3">
            <li className="bg-[#FD92101F] text-sm 2xl:text-base font-normal text-[#FD9210] w-fit rounded-full px-5 py-1">2022</li>
            <li className="bg-[#6B40F2]/10 text-sm 2xl:text-base font-normal text-[#6B40F2] w-fit rounded-full px-5 py-1">Capacity - 35 pax</li>
            <li className="bg-[#3A89C2]/10 text-sm 2xl:text-base font-normal text-[#3A89C2] w-fit rounded-full px-5 py-1">Predicted Booking</li>
            <li className="bg-[#369C8E]/10 text-sm 2xl:text-base font-normal text-[#369C8E] w-fit rounded-full px-5 py-1">Actual Booking</li>
            <li className="bg-[#E92531]/10 text-sm 2xl:text-base font-normal text-[#E92531] w-fit rounded-full px-5 py-1">Breakeven</li>
          </ul>
        </div>
      </div>
      <h4 className="text-2xl font-semibold text-[#3C3E49] mb-6">
        Recommended Dynamic Pricing-tours with multiple times
      </h4>
      <div className="flex justify-end mb-6">
        <DateRangePicker
          disabled={filters.yearType === "calendar"}
          title={
            filters.yearType === "calendar"
              ? ""
              : "Change Data by Calendar Year to change dates"
          }
          value={{
            startDate: filters.startDate,
            endDate: filters.endDate,
            compare: filters.compare,
            compareStart: filters.compareStart,
            compareEnd: filters.compareEnd,
          }}
          isCompare={true}
          onChange={(e) => onRangeChange(e)}
          fullWidth={false}
        />
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large mb-6">
        <div className="scrollbar w-full overflow-auto">
          <table className="w-full">
            <thead className="">
              <tr className="w-full">
                <th className=" text-left cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4 rounded-tl-large">
                  <div className="flex justify-center items-center">
                    Time
                    <MdAccessTime className="ml-1 inline-block" />
                  </div>
                </th>
                <th className="text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Sun
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Mon
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Tue
                </th>
                <th className="text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Wed
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Thu
                </th>
                <th className="text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Fri
                </th>
                <th className="cursor-pointer text-[#82838B] font-normal text-sm text-center px-3.5 bg-[#F7FAFF] !py-4 rounded-tr-large">
                  Sat
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-l-0 text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  9 am to 10 am
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border-r-0 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h4 className="text-2xl font-semibold text-[#3C3E49] mb-6">
        Data-Driven Dynamic Pricing
      </h4>
      <div className="flex w-full gap-2 items-center mb-6">
        <div class="w-full max-w-[300px] !p-1 bg-[#F1F3F9] border border-gray-200 rounded-extra_large flex items-center gap-2">
          <label class="flex-1 mb-0 flex items-center justify-center gap-2 rounded-large text-sm font-medium py-2 px-3 focus:outline-none text-[#1D2433A6]">
            Data Driven
          </label>
          <label class="flex-1 mb-0 flex items-center justify-center gap-2 rounded-large text-sm font-medium py-2 px-3 focus:outline-none text-typo bg-white cursor-pointer shadow-sm border-2 border-primary">
            Rule Based
          </label>
        </div>
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large w-full mb-8">
        <div className="flex justify-end p-5 gap-2 flex-wrap">
          <SelectDropdown
            displayValue="name"
            placeholder="Select Chart"
            options={options}
            noDefault={true}
          />
          <SelectDropdown
            displayValue="name"
            placeholder="This Month"
            options={months}
            noDefault={true}
          />
        </div>
        <div className="relative">
          <PricingCurveLineGraph />
          <ul className="flex flex-col absolute space-y-5 top-10 right-3">
            <li className="bg-[#FD92101F] text-sm 2xl:text-base font-normal text-[#FD9210] w-fit rounded-full px-5 py-1">2022</li>
            <li className="bg-[#6B40F2]/10 text-sm 2xl:text-base font-normal text-[#6B40F2] w-fit rounded-full px-5 py-1">Capacity - 35 pax</li>
            <li className="bg-[#3A89C2]/10 text-sm 2xl:text-base font-normal text-[#3A89C2] w-fit rounded-full px-5 py-1">Predicted Booking</li>
            <li className="bg-[#369C8E]/10 text-sm 2xl:text-base font-normal text-[#369C8E] w-fit rounded-full px-5 py-1">Actual Booking</li>
            <li className="bg-[#E92531]/10 text-sm 2xl:text-base font-normal text-[#E92531] w-fit rounded-full px-5 py-1">Breakeven</li>
          </ul>
        </div>
      </div>
      <h4 className="text-2xl font-semibold text-[#3C3E49] mb-6">
        Recommended Dynamic Pricing- Day Tours
      </h4>
      <div className="flex justify-end mb-6">
        <DateRangePicker
          disabled={filters.yearType === "calendar"}
          title={
            filters.yearType === "calendar"
              ? ""
              : "Change Data by Calendar Year to change dates"
          }
          value={{
            startDate: filters.startDate,
            endDate: filters.endDate,
            compare: filters.compare,
            compareStart: filters.compareStart,
            compareEnd: filters.compareEnd,
          }}
          isCompare={true}
          onChange={(e) => onRangeChange(e)}
          fullWidth={false}
        />
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large mb-6">
        <div className="scrollbar w-full overflow-auto">
          <table className="w-full">
            <thead className="">
              <tr className="w-full">
                <th className=" text-left cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4 rounded-tl-large">
                  <div className="flex justify-center items-center">
                    Time
                    <MdAccessTime className="ml-1 inline-block" />
                  </div>
                </th>
                <th className="text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Sun
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Mon
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Tue
                </th>
                <th className="text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Wed
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Thu
                </th>
                <th className="text-center cursor-pointer text-[#82838B] font-normal text-sm border-r border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Fri
                </th>
                <th className="cursor-pointer text-[#82838B] font-normal text-sm text-center px-3.5 bg-[#F7FAFF] !py-4 rounded-tr-large">
                  Sat
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 border-l-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  9 am to 10 am
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border-r-0 border text-center border-[#EAECF0]">
                  AUD $25.00{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-fit mt-4 border bg-white border-grey py-4 px-6 rounded-large mb-8">
        <h3 className="text-lg font-semibold mb-3">Settings</h3>
        <div className="relative flex items-center mb-5">
          <label className="text-sm mr-4">Lowest Price</label>
          <SelectDropdown
            placeholder="$ or %"
            options={CostinDropOptions}
            className="w-full max-w-[102px] mr-2"
          />
          <div className="relative">
            <input
              type="number"
              placeholder="Placeholder"
              className="shadow-box max-w-[111px] border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary pr-3"
              name="gst_per"
              min={0}
              required
            />
          </div>
        </div>
        <div className="relative flex items-center">
          <label className="text-sm mr-3">Highest Price</label>
          <SelectDropdown
            placeholder="$ or %"
            options={CostinDropOptions}
            className="w-full max-w-[102px] mr-2"
          />
          <div className="relative">
            <input
              type="number"
              placeholder="Placeholder"
              className="shadow-box max-w-[111px] border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary pr-3"
              name="gst_per"
              min={0}
              required
            />
          </div>
        </div>
      </div>
      <div className="border bg-white border-grey py-4 px-6 rounded-large mb-8">
        <h3 className="text-lg font-semibold mb-3">Based on Lead times</h3>
        <div className="flex justify-between flex-col gap-2">
          {form?.variable_costs?.map((item, index) => (
            <div className="flex gap-3 mb-2 items-center flex-wrap">
              <label className="text-sm">If my capacity</label>
              <div>
                <SelectDropdown
                  placeholder="$ or %"
                  options={CostinDropOptions}
                  className="w-full"
                />
                {CostingSubmitted && !item?.cal_type ? (
                  <span className="text-red-600 ml-2">Type is required</span>
                ) : null}
              </div>
              <div>
                <input
                  placeholder="%"
                  className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                  name="label"
                  required
                />
              </div>
              <SelectDropdown
                placeholder="Leads Times"
                options={CostinDropOptions}
                className=""
              />
              <SelectDropdown
                placeholder="Increase/Decrease"
                options={CostinDropOptions}
                className=""
              />
              <SelectDropdown
                placeholder="% or Amount"
                options={CostinDropOptions}
                className=""
              />
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="% or Amount"
                  className="shadow-box border !border-grey bg-white text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                  name="value"
                  value={item?.value}
                  required
                />
                {form?.variable_costs?.length == 1 ? (
                  <button
                    type="button"
                    style={{ visibility: "hidden" }}
                    title="Delete"
                    onClick={() => RemoveCosting(index, "variable_costs")}
                    className="  shrink-0   border cursor-pointer ml-3  !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                  >
                    <BsTrash3 />
                  </button>
                ) : (
                  <button
                    type="button"
                    title="Delete"
                    onClick={() => RemoveCosting(index, "variable_costs")}
                    className="  shrink-0   border cursor-pointer ml-3  !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                  >
                    <BsTrash3 />
                  </button>
                )}
                <div className="ml-2">
                  <button
                    type="button"
                    title="Add More"
                    className="!px-3.5 text-typo hover:!text-typo hover:no-underline text-sm font-medium py-3.5 flex items-center justify-center gap-2 bg-[#fff] rounded-extra_large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                    onClick={(e) => HandleAddCosting("variable_costs")}
                  >
                    <FiPlus />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="shadow-box border border-grey bg-white p-6 rounded-large mb-6">
        <h4 className="text-lg font-bold text-[#414141] mb-4">
          Would you like to apply above data driven dynamic pricing
          recommendations?
        </h4>
        <div className="flex gap-3 items-center mb-4">
          <div className="flex gap-1 items-center">
            <input
              type="checkbox"
              className="h-4 w-4 cursor-pointer shrink-0 rounded-[4px] border border-[#3C3E49A3] !text-primary"
            />
            <span className="text-sm ">Yes</span>
          </div>
          <div className="flex gap-1 items-center">
            <input
              type="checkbox"
              className="h-4 w-4 cursor-pointer shrink-0 rounded-[4px] border border-[#3C3E49A3] !text-primary"
            />
            <span className="text-sm">No</span>
          </div>
        </div>
        <button
          type="button"
          className="border border-primary h-10 shadow-btn px-6 hover:bg-primary hover:text-white text-sm text-primary transition-all rounded-extra_large"
        >
          Save
        </button>
      </div>
    </Layout>
  );
};

export default Pricing;
